<script setup lang="ts">
  import { NavItem } from "@/types/Homepage";
  import constants from "@ENUMS/constants";
  import Routes from "@/enums/Routes";

  const navItems: NavItem[] = [
    {
      title: "Home",
      link: "/",
    },
    {
      title: constants.BRAND_NAME,
      link: "#",
      dropdown: true,
      dropdownItems: [
        {
          title: "History",
          link: `${Routes.INNER.path}/brief-history`,
        },
      ],
    },
    {
      title: "Our Services",
      link: "#",
      dropdown: true,
      dropdownItems: [
        {
          title: "About Family Life Enrichment",
          link: `${Routes.INNER.path}/about-family-life-enrichment`,
        },
        {
          title: "Family Life Education",
          link: `${Routes.FAMILY_LIFE_EDUCATION.path}`,
        },
        {
          title: "Educational Empowerment (Scholarships)",
          link: {
            name: Routes.SERVICES_SUPPORT_ITEM.name,
            params: {
              id: "educational-empowerment",
            },
          },
        },
        {
          title: "Social Support for Single Women",
          link: {
            name: Routes.SERVICES_SUPPORT_ITEM.name,
            params: {
              id: "social-support-for-single-women",
            },
          },
        },
      ],
    },
    {
      title: "Gallery",
      link: `#`,
      dropdown: true,
      dropdownItems: [
        {
          title: "Photos",
          link: `${Routes.PHOTOS.path}`,
        },
        {
          title: "Videos",
          link: `${Routes.VIDEOS.path}`,
        },
      ],
    },
    {
      title: "Blog",
      link: `${Routes.BLOG.path}`,
    },
    {
      title: "Contact",
      link: "#",
    },
  ];
</script>

<template>
  <header
    class="tw-bg-white tw-text-black tw-top-0 tw-left-0 tw-right-0 tw-w-full tw-fixed tw-shadow-md"
  >
    <nav
      class="tw-container tw-pl-4 tw-pr-0 lg:tw-pr-4 tw-mx-auto tw-flex tw-items-center tw-justify-between"
    >
      <div
        class="left tw-text-solid tw-font-bold tw-flex tw-items-center tw-pr-8 tw-shrink-0"
      >
        <router-link to="/" class="tw-text-xl">
          kḥeḥseḏ
          <span class="tw-font-normal tw-text-xs">Inc.</span>
        </router-link>
      </div>

      <div class="right tw-overflow-auto lg:tw-overflow-hidden">
        <ul class="tw-flex tw-gap-2">
          <li v-for="(item, i) in navItems" :key="i" class="tw-shrink-0">
            <RouterLink
              v-if="!item.dropdown"
              :to="item.link || '#'"
              class="tw-flex tw-justify-center tw-items-center tw-font-medium tw-p-4 tw-border-b-4 tw-border-transparent"
            >
              {{ item.title }}
            </RouterLink>

            <ElDropdown v-else trigger="click">
              <a
                class="tw-flex tw-justify-center tw-items-center tw-font-medium tw-p-4 tw-border-b-4 tw-border-transparent"
              >
                {{ item.title }}
                <Material icon="expand_more" />
              </a>
              <template #dropdown>
                <ElDropdownMenu>
                  <ElDropdownItem
                    v-for="(sub, i) in item.dropdownItems"
                    :key="i"
                  >
                    <RouterLink :to="sub.link || '#'" class="tw-grow">
                      {{ sub.title }}
                    </RouterLink>
                  </ElDropdownItem>
                </ElDropdownMenu>
              </template>
            </ElDropdown>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<style lang="scss" scoped>
  header {
    z-index: $k-main-header;
    nav {
      .left {
        height: $k-nav-height;
      }

      .right {
        ul {
          li {
            a {
              height: $k-nav-height;

              @apply tw-transition-colors;

              &.active {
                background-color: $k-background-hex;
              }

              &.active,
              &:hover {
                color: $k-primary-hex;
                border-bottom-color: $k-primary-hex;
              }
            }
          }
        }
      }
    }
  }
</style>

<style lang="scss">
  .el-dropdown-menu {
    &__item {
      width: 17rem;
    }
  }
</style>
