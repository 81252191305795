import { handleMaterialFontsVisibility } from "@UTILS/dom/domFunctions";

function addMaterialFontListener(remove = false) {
  const fontScriptId = "material-icons";

  if (remove) {
    const foundElement = document.getElementById(fontScriptId);

    if (foundElement !== null) {
      foundElement?.removeEventListener(
        "load",
        handleMaterialFontsVisibility
      );
    }

    return;
  }

  let element: HTMLLinkElement;

  element = document.createElement("link");
  element.rel = "stylesheet";
  // Material design symbols
  // loads the entire Variable Font
  element.href =
    "https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200";
  element.id = fontScriptId;

  element?.addEventListener("load", handleMaterialFontsVisibility);

  document.head.appendChild(element);
}

function InitListeners() {
  addMaterialFontListener();
}

export default InitListeners;

export { addMaterialFontListener };
