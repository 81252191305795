import { addMaterialFontListener } from "@UTILS/dom/domEventListeners";

function handleMaterialFontsVisibility() {
  const iconsHidden = "icons-hidden";
  const iconsLoading = "icons-loading";
  const iconsVisible = "icons-visible";

  document.body.classList.remove(iconsHidden);
  document.body.classList.add(iconsLoading);

  setTimeout(() => {
    document.body.classList.remove(iconsLoading);
    document.body.classList.add(iconsVisible);
  }, 3000);

  addMaterialFontListener(true);
}

export { handleMaterialFontsVisibility };
