import { createApp } from "vue";
import { register } from "swiper/element/bundle";
import { VueQueryPlugin } from "@tanstack/vue-query";
import { v4 as uuidv4 } from "uuid";

import Api from "@/utils/api";

import router from "@/router";

import App from "@/App.vue";

register();

const app = createApp(App);

app.provide("$api", Api);
app.provide("$uuid", uuidv4);

app.use(router);
app.use(VueQueryPlugin);

app.mount("#app");
