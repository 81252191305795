<script setup lang="ts">
  import { AnOutcomeItem } from "@/types/Homepage";
  import Routes from "@/enums/Routes";

  const outcomes: AnOutcomeItem[] = [
    {
      name: "Increased Social Capital",
      thumbnailImage:
        "https://res.cloudinary.com/devkhoded/image/upload/v1593789615/social_3285ba97d5.png",
    },
    {
      name: "Emotional Intelligence",
      thumbnailImage:
        "https://res.cloudinary.com/devkhoded/image/upload/v1593789605/emotion_eaf3fa6b9f.png",
    },
    {
      name: "Helping You Become Relationship Savvy",
      thumbnailImage:
        "https://res.cloudinary.com/devkhoded/image/upload/v1593789613/relationship_eb586fb98d.png",
    },
  ];
</script>

<template>
  <section
    class="tw-my-5 tw-flex tw-justify-center tw-items-center outcomes xl:tw-my-8"
  >
    <div
      class="tw-py-5 tw-container tw-mx-auto tw-px-4 outcomes__inner tw-relative"
    >
      <div class="tw-text-center outcomes__inner__title">
        <h5
          class="tw-pb-5 tw-text-lg tw-font-light lg:tw-text-xl xl:tw-text-3xl"
        >
          ...and here comes...
        </h5>
        <h1
          class="tw-text-4xl tw-italic tw-font-semibold tw-text-solid tw-pb-10 lg:tw-text-6xl xl:tw-text-8xl"
        >
          Our Outcomes
          <span class="tw-text-sm tw-text-black tw-font-light">
            (no pun intended)
          </span>
        </h1>
      </div>
      <div
        class="tw-pt-5 list tw-justify-center tw-flex tw-flex-wrap outcomes__inner__list tw-relative"
      >
        <figure
          v-for="(outcome, i) in outcomes"
          :key="i"
          class="tw-rounded-lg tw-transition-all tw-duration-500 tw-ease-in-out tw-w-1/2 tw-relative tw-flex tw-items-center tw-flex-col tw-p-3 tw-text-center md:tw-w-1/3 lg:tw-w-1/4 xl:tw-w-1/5 xl:tw-py-12 xl:tw-px-8"
        >
          <span
            class="tw-mb-3 xl:tw-mb-8 tw-rounded-full tw-shadow-2xl tw-transition-all tw-duration-500 tw-ease-in-out tw-w-24 tw-h-24 tw-flex tw-items-center tw-justify-center tw-bg-white xl:tw-w-48 xl:tw-h-48"
          >
            <img :src="outcome.thumbnailImage" class="tw-w-8 xl:tw-w-24" />
          </span>

          <figcaption>
            <h4
              class="tw-pb-4 tw-text-base tw-font-normal tw-text-solid xl:tw-text-2xl"
            >
              {{ outcome.name }}
            </h4>
            <p
              v-if="false"
              class="tw-text-gray-600 tw-pb-3 tw-text-sm xl:tw-pb-7 xl:tw-text-base"
            >
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
            </p>

            <div class="cta tw-invisible">
              <ElButton round text>
                <Material icon="arrow_right_alt" />
              </ElButton>
            </div>
          </figcaption>
        </figure>
      </div>

      <p class="tw-text-center tw-text-base lg:tw-text-lg tw-text-black">
        We deliver skills, attitudes and values for a vibrant you
      </p>

      <div
        class="tw-pt-10 tw-flex tw-gap-4 tw-justify-center tw-flex-wrap"
      >
        <ElButton v-if="false" type="primary">Buy a product</ElButton>
        <ElButton type="primary">
          <RouterLink
            :to="`${Routes.INNER.path}/about-family-life-enrichment`"
          >
            Learn More
          </RouterLink>
        </ElButton>
        <ElButton v-if="false" round>
          <Material icon="phone" />
        </ElButton>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  .outcomes {
    &__inner {
      &__list {
        &::before {
        }
      }
    }
  }
</style>
