<script setup lang="ts">
  import { SwiperOptions } from "swiper/types";
  import { onMounted } from "vue";
  import { HomeSlider, swiperElement } from "@TYPES/Homepage";
  import Routes from "@/enums/Routes";

  const backgroundSwiperId = "home-slider-background-id";
  const cardSwiperId = "home-slider-card-id";
  const commonSwiperParams: SwiperOptions = {
    loop: true,
    speed: 3000,
  };

  const slides: HomeSlider[] = [
    {
      backgroundImage: "/imgs/bgs/couple-3.jpg",
      title: {
        heading: "kḥeḥseḏ",
        subtitle: `(kheh'-sed)`,
        points: ["Grace", "Mercy", "Loving Kindness"],
      },
      description: `Khehsed Inc. was established in 2023 .  It exists to help families and communities experience God’s grace, mercy and loving kindness through family life education in communities, social and economic support for indigent single persons and children.`,
      actionButtons: [
        {
          type: "",
          title: "Learn More",
          link: `${Routes.INNER.path}/about-family-life-enrichment`,
        },
        {
          type: "primary",
          title: "View Offers",
          link: {
            name: Routes.SERVICES_SUPPORT_ITEM.name,
            params: {
              id: "educational-empowerment",
            },
          },
        },
      ],
    },
  ];

  function initSwiper() {
    const backgroundSwiper: swiperElement =
      document.getElementById(backgroundSwiperId);
    const cardSwiper: swiperElement =
      document.getElementById(cardSwiperId);

    const backgroundSwiperOptions: SwiperOptions = {
      ...commonSwiperParams,
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
    };
    const cardSwiperOptions: SwiperOptions = {
      ...commonSwiperParams,
      parallax: true,
      spaceBetween: 30,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      on: {
        slideChange(s) {
          backgroundSwiper?.swiper?.slideTo(s.activeIndex);
        },
      },
    };

    if (backgroundSwiper !== null && cardSwiper !== null) {
      Object.assign(backgroundSwiper, backgroundSwiperOptions);
      Object.assign(cardSwiper, cardSwiperOptions);

      backgroundSwiper.initialize();
      cardSwiper.initialize();
    }
  }

  onMounted(() => {
    initSwiper();
  });
</script>

<template>
  <section
    class="tw-h-[90vh] tw-bg-primary slider sm:tw-h-[80vh] md:tw-h-[70vh] lg:tw-h-[70vh] xl:tw-h-[80vh] tw-relative"
  >
    <swiper-container
      :id="backgroundSwiperId"
      class="tw-h-full slider__list"
      init="false"
    >
      <swiper-slide
        v-for="(slide, i) in slides"
        :key="i"
        class="tw-h-full tw-flex tw-items-end tw-p-4 slider__list__item xl:tw-items-center tw-relative"
      >
        <div
          class="tw-absolute tw-top-0 tw-bottom-0 tw-left-0 tw-ring-0 tw-w-full tw-h-full slider__list__item__bg"
          :style="{
            backgroundImage: `url(${slide.backgroundImage})`,
          }"
        ></div>
      </swiper-slide>
    </swiper-container>
    <div
      class="tw-h-full slider__list tw-top-0 tw-right-0 tw-left-0 tw-bottom-0 tw-w-full tw-absolute"
    >
      <div
        class="tw-h-full tw-flex tw-items-end tw-p-4 slider__list__item xl:tw-items-center tw-relative"
      >
        <div
          class="slider__list__item__inner tw-w-full tw-container tw-mx-auto"
        >
          <swiper-container
            :id="cardSwiperId"
            class="slider__list__item__inner__container xl:tw-w-1/2 tw-mx-0 tw-flex tw-items-end"
            init="false"
          >
            <swiper-slide
              v-for="(slide, i) in slides"
              :key="i"
              class="tw-bg-white tw-bg-opacity-75 tw-relative tw-rounded-sm slider__list__item__inner__container__item tw-backdrop-blur-sm tw-shadow-lg tw-w-full tw-shrink-0 tw-self-end"
            >
              <h1
                class="tw-pt-6 tw-px-8 tw-text-solid slider__list__item__inner__container__item__title"
                data-swiper-parallax="-100"
              >
                <div class="tw-pb-2">
                  <span class="tw-text-3xl">
                    {{ slide.title.heading }}
                  </span>
                  <span v-if="slide.title.subtitle" class="tw-text-base">
                    {{ slide.title.subtitle }}
                  </span>
                </div>
                <ul v-if="slide.title.points?.length" class="tw-text-sm">
                  <li v-for="(point, i) in slide.title.points" :key="i">
                    {{ point }}
                  </li>
                </ul>
              </h1>

              <p
                class="tw-pt-6 tw-px-8 tw-text-base"
                data-swiper-parallax="-200"
              >
                {{ slide.description }}
              </p>

              <div
                class="ctas tw-px-8 tw-pt-6 tw-pb-8 tw-flex tw-gap-3 tw-flex-wrap"
                data-swiper-parallax="-300"
              >
                <ElButton
                  v-for="(cta, i) in slide.actionButtons"
                  :key="i"
                  :type="cta.type"
                  :plain="cta.plain"
                >
                  <RouterLink v-if="cta.link" :to="cta.link">
                    Learn More
                  </RouterLink>

                  <template v-else>
                    {{ cta.title }}
                  </template>
                </ElButton>
              </div>
            </swiper-slide>
          </swiper-container>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  .slider {
    &__list {
      &__item {
        padding-top: $k-nav-height;
        &__bg {
          @include bgImageDefault("");
        }
        &__inner {
          &__container {
            &__item {
              &__title {
                ul {
                  display: flex;
                  li {
                    &:not(:last-child) {
                      &::after {
                        content: "circle";
                        font-family: "Material Symbols Outlined";

                        font-variation-settings: "FILL" 1, "wght" 400,
                          "GRAD" 0, "opsz" 24;
                        font-size: 0.5rem;
                        padding: 0 0.5rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
