<script setup lang="ts">
  import { SwiperOptions } from "swiper/types";
  import { swiperElement } from "@TYPES/Homepage";
  import { computed, onMounted, watch } from "vue";
  import { useRoute } from "vue-router";
  import { useQuery } from "@tanstack/vue-query";
  import { fetchOfferingPage } from "@ENUMS/pages";

  import PageHeader from "@COMP/base/utilities/PageHeader.vue";
  import PageError from "@COMP/base/skeletons/PageError.vue";
  import InnerPageSkeleton from "@COMP/base/skeletons/InnerPageSkeleton.vue";

  const route = useRoute();

  const pageId = computed(() => route.params.id as string);
  const sliderId = "offering-slider";
  const { isPending, isError, data, refetch } = useQuery({
    queryKey: ["offeringPage", pageId],
    queryFn: () => fetchOfferingPage(pageId.value),
    staleTime: Infinity,
  });

  function initSwiper() {
    const slider: swiperElement = document.getElementById(sliderId);

    const sliderOptions: SwiperOptions = {
      loop: true,
      speed: 3000,
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
    };

    if (slider !== null) {
      Object.assign(slider, sliderOptions);

      slider.initialize();
    }
  }

  watch(
    () => data.value,
    (newValue) => {
      if (newValue) {
        setTimeout(() => {
          initSwiper();
        });
      }
    },
    { deep: true }
  );

  onMounted(() => {
    initSwiper();
  });
</script>

<template>
  <div class="inner-page">
    <div class="inner-page__inner">
      <InnerPageSkeleton v-if="isPending" />
      <PageError v-else-if="isError" :retry="refetch" />
      <template v-else-if="data">
        <PageHeader :title="data.title" />
        <div class="tw-container tw-px-4 tw-mx-auto">
          <div v-if="data.banners" class="lg:tw-max-w-4xl tw-mx-auto">
            <div
              class="sm:tw-h-[50vh] md:tw-h-[50vh] lg:tw-h-[50vh] xl:tw-h-[50vh] tw-relative tw-h-[50vh]"
            >
              <swiper-container
                :id="sliderId"
                init="false"
                class="tw-h-full tw-w-full tw-block"
              >
                <swiper-slide
                  v-for="(img, i) in data.banners"
                  class="tw-bg-primary tw-h-full tw-flex tw-items-end tw-p-4 xl:tw-items-center tw-relative"
                  :key="i"
                >
                  <div
                    class="banner tw-absolute tw-top-0 tw-bottom-0 tw-left-0 tw-ring-0 tw-w-full tw-h-full"
                    :style="{
                      backgroundImage: `url(${img})`,
                    }"
                  ></div>
                </swiper-slide>
              </swiper-container>
            </div>
          </div>
        </div>
        <div class="tw-my-8 tw-container tw-px-4 tw-mx-auto">
          <div
            class="tw-bg-white tw-shadow-md tw-px-8 tw-py-8 lg:tw-max-w-4xl tw-mx-auto"
          >
            <div
              class="tw-prose tw-prose-slate lg:tw-prose-lg tw-max-w-none tw-bg-white"
              v-html="data.content"
            ></div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .inner-page {
    &__inner {
      padding-top: $k-nav-height;
      .banner {
        @include bgImageDefault("");
      }
    }
  }
</style>
