<script setup lang="ts">
  import { onMounted } from "vue";
  import { VueQueryDevtools } from "@tanstack/vue-query-devtools";

  import InitListeners from "@UTILS/dom/domEventListeners";
  import RouterViews from "@ENUMS/RouterViews.ts";

  onMounted(() => {
    InitListeners();
  });
</script>

<template>
  <div>
    <ElConfigProvider :size="'large'" :z-index="2000">
      <RouterView :name="RouterViews.MAIN_HEADER" />

      <RouterView :name="RouterViews.MAIN_CONTENT" />

      <RouterView :name="RouterViews.MAIN_FOOTER" />
    </ElConfigProvider>

    <VueQueryDevtools />
  </div>
</template>
