<script setup lang="ts">
  import { computed } from "vue";
  import { useRoute } from "vue-router";
  import { useQuery } from "@tanstack/vue-query";
  import { fetchInnerPage } from "@ENUMS/pages";

  import PageHeader from "@COMP/base/utilities/PageHeader.vue";
  import PageError from "@COMP/base/skeletons/PageError.vue";
  import InnerPageSkeleton from "@COMP/base/skeletons/InnerPageSkeleton.vue";

  const route = useRoute();

  const pageId = computed(() => route.params.id as string);

  const { isPending, isError, data, refetch } = useQuery({
    queryKey: ["innerPage", pageId],
    queryFn: () => fetchInnerPage(pageId.value),
    staleTime: Infinity,
  });
</script>

<template>
  <div class="inner-page">
    <div class="inner-page__inner">
      <InnerPageSkeleton v-if="isPending" />
      <PageError v-else-if="isError" :retry="refetch" />
      <template v-else-if="data">
        <PageHeader :title="data.title" />
        <div
          v-if="data.banner"
          class="banner tw-h-[50vh] tw-bg-primary slider sm:tw-h-[50vh] md:tw-h-[50vh] lg:tw-h-[50vh] xl:tw-h-[50vh] tw-relative"
          :style="{
            backgroundImage: `url(${data.banner})`,
          }"
        ></div>
        <div class="tw-my-8 tw-container tw-px-4 tw-mx-auto">
          <div
            class="tw-bg-white tw-shadow-md tw-px-8 tw-py-8 lg:tw-max-w-4xl tw-mx-auto"
          >
            <div
              class="tw-prose tw-prose-slate lg:tw-prose-lg tw-max-w-none"
              v-html="data.content"
            ></div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .inner-page {
    &__inner {
      padding-top: $k-nav-height;
      .banner {
        @include bgImageDefault("");
      }
    }
  }
</style>
