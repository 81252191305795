<script setup lang="ts">
  import { computed } from "vue";
  import { useRoute } from "vue-router";
  import { RouteItem } from "@ENUMS/Routes";

  const props = defineProps({
    title: {
      type: String,
      required: true,
    },
    center: {
      type: Boolean,
      default: true,
    },
  });

  const route = useRoute();

  const breadcrumbs = computed<RouteItem[]>(() => {
    return route.meta?.breadcrumbs as any;
  });
</script>

<template>
  <div
    class="tw-container tw-px-4 tw-py-8"
    :class="[{ 'tw-mx-auto': center }]"
  >
    <div class="lg:tw-max-w-4xl" :class="[{ 'tw-mx-auto': center }]">
      <div v-if="breadcrumbs && breadcrumbs.length" class="tw-pb-4">
        <ElBreadcrumb>
          <ElBreadcrumbItem
            v-for="(crumb, i) in breadcrumbs"
            :key="i"
            :to="{
              name: crumb.name,
            }"
          >
            {{ crumb.title }}
          </ElBreadcrumbItem>
        </ElBreadcrumb>
      </div>
      <h5 class="tw-font-bold tw-text-3xl">{{ props.title }}</h5>
    </div>
  </div>
</template>
