<script setup lang="ts">
  import { SwiperOptions } from "swiper/types";
  import { swiperElement } from "@TYPES/Homepage";
  import { computed, watch, onMounted } from "vue";
  import { useRoute } from "vue-router";
  import { useQuery } from "@tanstack/vue-query";
  import { fetchOfferingPage } from "@ENUMS/pages";

  import PageHeader from "@COMP/base/utilities/PageHeader.vue";
  import PageError from "@COMP/base/skeletons/PageError.vue";
  import InnerPageSkeleton from "@COMP/base/skeletons/InnerPageSkeleton.vue";

  const route = useRoute();

  const sliderId = "offering-slider";
  const pageId = computed(() => route.params.id as string);

  const { isPending, isError, data, refetch } = useQuery({
    queryKey: ["offeringPage", pageId],
    queryFn: () => fetchOfferingPage(pageId.value),
    staleTime: Infinity,
  });

  function initSwiper() {
    const slider: swiperElement = document.getElementById(sliderId);

    const sliderOptions: SwiperOptions = {
      loop: true,
      speed: 3000,
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
    };

    if (slider !== null) {
      Object.assign(slider, sliderOptions);

      slider.initialize();
    }
  }

  watch(
    () => data.value,
    (newValue) => {
      if (newValue) {
        setTimeout(() => {
          initSwiper();
        });
      }
    },
    { deep: true }
  );

  onMounted(() => {
    initSwiper();
  });
</script>

<template>
  <div class="offering-page">
    <div class="offering-page__inner">
      <InnerPageSkeleton v-if="isPending" />
      <PageError v-else-if="isError" :retry="refetch" />
      <template v-else-if="data">
        <div class="tw-my-8 md:tw-flex tw-gap-8">
          <div class="lg:tw-max-w-4xl">
            <PageHeader
              :title="data.title"
              :center="false"
              class="tw-pt-0"
            />
            <div>
              <div v-if="data.banners">
                <div
                  class="sm:tw-h-[50vh] md:tw-h-[50vh] lg:tw-h-[50vh] xl:tw-h-[50vh] tw-relative tw-h-[50vh]"
                >
                  <swiper-container
                    :id="sliderId"
                    init="false"
                    class="tw-h-full tw-w-full tw-block"
                  >
                    <swiper-slide
                      v-for="(img, i) in data.banners"
                      class="tw-bg-primary tw-h-full tw-flex tw-items-end tw-p-4 xl:tw-items-center tw-relative"
                      :key="i"
                    >
                      <div
                        class="banner tw-absolute tw-top-0 tw-bottom-0 tw-left-0 tw-ring-0 tw-w-full tw-h-full"
                        :style="{
                          backgroundImage: `url(${img})`,
                        }"
                      ></div>
                    </swiper-slide>
                  </swiper-container>
                </div>
              </div>
            </div>
            <div>
              <div class="tw-bg-white tw-shadow-md tw-px-8 tw-py-8">
                <div
                  class="tw-prose tw-prose-slate lg:tw-prose-lg tw-max-w-none tw-bg-white"
                  v-html="data.content"
                ></div>
              </div>
            </div>
          </div>
          <div>
            <div
              class="tw-p-4 tw-bg-white tw-border-gray-100 tw-shadow-lg"
            >
              <p class="tw-font-bold tw-text-2xl">
                Have you got an enquiry?
              </p>
              <p class="tw-text-sm tw-pt-4">
                We would love to hear from you. Please send us a message
              </p>

              <div class="tw-pt-4">
                <ElTooltip content="Coming soon...">
                  <ElButton type="primary" disabled>
                    Make an enquiry
                  </ElButton>
                </ElTooltip>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .offering-page {
    &__inner {
      .banner {
        @include bgImageDefault("");
      }
    }
  }
</style>
