<script setup lang="ts">
  import Date from "@UTILS/date";
  import { computed } from "vue";
  import { useRoute } from "vue-router";
  import { useQuery } from "@tanstack/vue-query";
  import { fetchBlogContent } from "@ENUMS/pages";

  const route = useRoute();

  const pageId = computed(() => route.params.id as string);
  const { isPending, isError, data, refetch } = useQuery({
    queryKey: ["blog-content", pageId],
    queryFn: () => fetchBlogContent(pageId.value),
    staleTime: Infinity,
  });
</script>

<template>
  <div class="inner-page">
    <div class="inner-page__inner">
      <InnerPageSkeleton v-if="isPending" />
      <PageError v-else-if="isError" :retry="refetch" />
      <template v-else-if="data">
        <PageHeader :title="data.title" />
        <div class="tw-container tw-px-4 tw-mx-auto">
          <div
            v-if="data.featuredImage.formats.medium?.url"
            class="lg:tw-max-w-4xl tw-mx-auto"
          >
            <p class="tw-pb-6 tw-text-sm">
              {{ Date(data.updatedAt).formatDMY() }}
            </p>
            <div
              class="sm:tw-h-[50vh] md:tw-h-[50vh] lg:tw-h-[50vh] xl:tw-h-[50vh] tw-relative tw-h-[50vh]"
            >
              <div
                class="tw-bg-primary tw-h-full tw-flex tw-items-end tw-p-4 xl:tw-items-center tw-relative"
              >
                <div
                  class="banner tw-absolute tw-top-0 tw-bottom-0 tw-left-0 tw-ring-0 tw-w-full tw-h-full"
                  :style="{
                    backgroundImage: `url(${data.featuredImage.formats.medium?.url})`,
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="tw-my-8 tw-container tw-px-4 tw-mx-auto">
          <div
            class="tw-bg-white tw-shadow-md tw-px-8 tw-py-8 lg:tw-max-w-4xl tw-mx-auto"
          >
            <div
              class="tw-prose tw-prose-slate lg:tw-prose-lg tw-max-w-none tw-bg-white"
              v-html="data.content"
            ></div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .inner-page {
    &__inner {
      padding-top: $k-nav-height;
      .banner {
        @include bgImageDefault("");
      }
    }
  }
</style>
