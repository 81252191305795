<script setup lang="ts">
  import { useQuery } from "@tanstack/vue-query";
  import { fetchVideos } from "@ENUMS/pages";

  const { isPending, isError, data } = useQuery({
    queryKey: ["videos"],
    queryFn: () => fetchVideos(),
    staleTime: Infinity,
  });
</script>

<template>
  <section
    class="tw-flex tw-justify-center tw-items-center home-video tw-relative tw-mt-8 before:tw-transition-all before:tw-duration-[3s]"
  >
    <div
      class="home-video__inner tw-py-5 tw-container tw-mx-auto tw-pb-16 tw-pt-5 tw-px-4"
    >
      <div class="home-video__inner__title">
        <h5
          class="tw-pb-5 xk:tw-text-3xl tw-font-light tw-text-lg lg:tw-text-xl"
        >
          We saved some clips for you.
        </h5>
        <h1
          class="xl:tw-text-7xl tw-italic tw-font-semibold tw-text-solid tw-pb-10 tw-drop-shadow-lg lg:tw-text-6xl tw-text-4xl"
        >
          Our Media Room
        </h1>
      </div>
      <div
        v-if="!isPending && !isError && data"
        class="tw-pt-5 tw-justify-center home-video__inner__list tw-flex tw-flex-wrap tw-relative before:tw-top-16 before:lg:tw-top-20 before:xl:tw-top-36"
      >
        <VideoPlayer
          v-for="(video, i) in data.videos.slice(0, 5)"
          :key="i"
          :video="video"
        />
      </div>
    </div>
  </section>
</template>

<style lang="scss">
  .home-video {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      @include checkeredBackground(0);
    }
    &:hover {
      &::before {
        opacity: 0.2;
      }
    }
    &__inner {
      &__list {
        &::before {
          content: "";
          position: absolute;
          right: 0;
          left: 0;
          height: 1px;
          background-color: $k-accent-hex;
        }
        figure {
          &:hover {
            span {
              transform: scale(1.3);
              @apply tw-shadow-none;
            }
            .cta {
              visibility: visible;
            }
          }
          & > span {
            @include bgImageDefault(
              "https://images.pexels.com/photos/1642883/pexels-photo-1642883.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            );
            transition: box-shadow 500ms ease-in-out, background-size 0.3s,
              transform 40s linear;
          }
        }
      }
    }
  }
</style>
