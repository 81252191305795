import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
} from "vue-router";

import RouterViews from "@/enums/RouterViews";
import Routes from "@/enums/Routes";

import MainFooter from "@COMP/MainFooter.vue";
import MainHeader from "@COMP/MainHeader.vue";

import Home from "@VIEWS/Home.vue";
import InnerPage from "@VIEWS/InnerPage.vue";
import OfferingPage from "@VIEWS/OfferingPage.vue";
import FamilyLifeEducation from "@VIEWS/FamilyLifeEducation.vue";
import FamilyLifeEducationItem from "@VIEWS/FamilyLifeEducationItem.vue";
import ServicesSupport from "@VIEWS/ServicesSupport.vue";
import ServicesSupportItem from "@VIEWS/ServicesSupportItem.vue";
import GalleryPage from "@VIEWS/GalleryPage.vue";
import PhotosPage from "@VIEWS/PhotosPage.vue";
import VideosPage from "@VIEWS/VideosPage.vue";
import BlogPage from "@VIEWS/BlogPage.vue";
import ViewBlogPage from "@VIEWS/ViewBlogPage.vue";

const routes: RouteRecordRaw[] = [
  {
    path: `${Routes.HOME.path}`,
    name: `${Routes.HOME.name}`,
    components: {
      [RouterViews.MAIN_HEADER]: MainHeader,
      [RouterViews.MAIN_CONTENT]: Home,
      [RouterViews.MAIN_FOOTER]: MainFooter,
    },
  },
  {
    path: `${Routes.INNER.path}/:id`,
    name: `${Routes.INNER.name}`,
    components: {
      [RouterViews.MAIN_HEADER]: MainHeader,
      [RouterViews.MAIN_CONTENT]: InnerPage,
      [RouterViews.MAIN_FOOTER]: MainFooter,
    },
  },
  {
    path: `${Routes.FAMILY_LIFE_EDUCATION.path}/`,
    name: `${Routes.FAMILY_LIFE_EDUCATION.name}`,
    components: {
      [RouterViews.MAIN_HEADER]: MainHeader,
      [RouterViews.MAIN_CONTENT]: FamilyLifeEducation,
      [RouterViews.MAIN_FOOTER]: MainFooter,
    },
    children: [
      {
        name: Routes.FAMILY_LIFE_EDUCATION_ITEM.name,
        path: `${Routes.FAMILY_LIFE_EDUCATION_ITEM.path}`,
        component: FamilyLifeEducationItem,
      },
    ],
  },
  {
    path: `${Routes.SERVICES_SUPPORT.path}/`,
    name: `${Routes.SERVICES_SUPPORT.name}`,
    components: {
      [RouterViews.MAIN_HEADER]: MainHeader,
      [RouterViews.MAIN_CONTENT]: ServicesSupport,
      [RouterViews.MAIN_FOOTER]: MainFooter,
    },
    children: [
      {
        name: Routes.SERVICES_SUPPORT_ITEM.name,
        path: `${Routes.SERVICES_SUPPORT_ITEM.path}`,
        component: ServicesSupportItem,
      },
    ],
  },
  {
    path: `${Routes.SERVICES.path}/:id`,
    name: `${Routes.SERVICES.name}`,
    components: {
      [RouterViews.MAIN_HEADER]: MainHeader,
      [RouterViews.MAIN_CONTENT]: OfferingPage,
      [RouterViews.MAIN_FOOTER]: MainFooter,
    },
  },
  {
    path: `${Routes.BLOG.path}`,
    name: `${Routes.BLOG.name}`,
    components: {
      [RouterViews.MAIN_HEADER]: MainHeader,
      [RouterViews.MAIN_CONTENT]: BlogPage,
      [RouterViews.MAIN_FOOTER]: MainFooter,
    },
  },
  {
    path: `${Routes.BLOG_CONTENT.path}/:id`,
    name: `${Routes.BLOG_CONTENT.name}`,
    components: {
      [RouterViews.MAIN_HEADER]: MainHeader,
      [RouterViews.MAIN_CONTENT]: ViewBlogPage,
      [RouterViews.MAIN_FOOTER]: MainFooter,
    },
    meta: {
      breadcrumbs: [Routes.HOME, Routes.BLOG, Routes.BLOG_CONTENT],
    },
  },
  {
    path: `${Routes.GALLERY.path}`,
    name: `${Routes.GALLERY.name}-post`,
    redirect: `${Routes.PHOTOS.path}`,
    components: {
      [RouterViews.MAIN_HEADER]: MainHeader,
      [RouterViews.MAIN_CONTENT]: GalleryPage,
      [RouterViews.MAIN_FOOTER]: MainFooter,
    },
    children: [
      {
        path: `${Routes.PHOTOS.path}`,
        name: `${Routes.PHOTOS.name}`,
        component: PhotosPage,
      },
      {
        path: `${Routes.VIDEOS.path}`,
        name: `${Routes.VIDEOS.name}`,
        component: VideosPage,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // Scroll to the top of the page before entering any route

  if (
    from.name === Routes.FAMILY_LIFE_EDUCATION_ITEM.name &&
    to.name === Routes.FAMILY_LIFE_EDUCATION_ITEM.name
  ) {
    next();
  } else {
    window.scrollTo(0, 0);
    next();
  }
});

export default router;
