<script setup lang="ts">
  import { onMounted, ref } from "vue";
  import { SwiperOptions } from "swiper/types";
  import { Testimonial, swiperElement } from "@TYPES/Homepage";

  const sliderId = "home-testimonial-slider";
  const commonSwiperParams: SwiperOptions = {
    loop: true,
    speed: 5000,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
  };
  const videoUrl = ref(
    "https://res.cloudinary.com/devkhoded/video/upload/v1599471801/energizing_video_0a76e6bd67.mp4"
  );
  const backgroundVideo = ref<HTMLVideoElement>();
  const testimonials: Testimonial[] = [
    {
      avatar: "",
      category: "General",
      name: "Tobi",
      comment:
        "The feel of a physical meeting. It felt different from other zoom meetings I had attended",
      verifiedBadge: false,
      subtitle: "",
    },
    {
      avatar: "",
      category: "General",
      name: "Deidra",
      comment:
        "The topic was much more broad that I expected, which added even more value for me.",
      verifiedBadge: false,
      subtitle: "",
    },
    {
      avatar: "",
      category: "General",
      name: "Esther",
      comment:
        "I loved your energy! ....It felt like most people were in a good, positive space. It is an interesting human phenomenon how positive people tend to gravitate and do positive focused things to maintain their state of being.",
      verifiedBadge: false,
      subtitle: "",
    },
    {
      avatar: "",
      category: "General",
      name: "Sheryl",
      comment:
        "Thankx for sharing webinar info.  Good info intertwined with spiritual application!",
      verifiedBadge: false,
      subtitle: "",
    },
    {
      avatar: "",
      category: "General",
      name: "Deidra",
      comment:
        "Good job!!  Good content!! Good mix of mental health and spiritual health. Nicely balanced.",
      verifiedBadge: false,
      subtitle: "",
    },
    {
      avatar:
        "https://res.cloudinary.com/devkhoded/image/upload/v1618507892/thumbnail_Damilola_c4895c2b71.jpg",
      category: "Fortify",
      name: "Damilola & Makinwa Solademi",
      comment:
        "We have learnt to acknowledge each others bids, have our US time regardless of the busy schedules and make decisions as a team. Thank you for sharing your wealth of knowledge and helping to fortify relationships. God Bless🙏🏼",
      verifiedBadge: true,
      subtitle: "Fortify Attendant",
    },
    {
      avatar:
        "https://res.cloudinary.com/devkhoded/image/upload/v1618507893/thumbnail_Severine_a1c8760908.jpg",
      category: "Fortify",
      name: "Severine & Michael Wogu",
      comment: "Thank you so much! This class was a true blessing",
      verifiedBadge: true,
      subtitle: "Fortify Attendant",
    },
    {
      avatar:
        "https://res.cloudinary.com/devkhoded/image/upload/v1618507894/thumbnail_Linda_83f1e70ab9.jpg",
      category: "Fortify",
      name: "Linda & Chester Williams",
      comment:
        "We discovered many tools to enhance and fortify our relationship. We appreciate and are taking advantage of the power of focusing on what’s strong. We fully endorse the Drs. Augusta & Israel Olaore’s marriage enrichment class!",
      verifiedBadge: true,
      subtitle: "Fortify Attendant",
    },
    {
      avatar:
        "https://res.cloudinary.com/devkhoded/image/upload/v1618507894/thumbnail_Michael_91e9cc9fa7.jpg",
      category: "Fortify",
      name: "Michael Olusegun Banjoko",
      comment:
        "I wish I had known these principles earlier than now. I recommend that all intending couples take this class. It is mind blowing!",
      verifiedBadge: true,
      subtitle: "Fortify Attendant",
    },
    {
      avatar:
        "https://res.cloudinary.com/devkhoded/image/upload/v1618507893/thumbnail_Genea_afdd73bc90.jpg",
      category: "Fortify",
      name: "Genea & Martin Adeniji",
      comment:
        "Because of Fortify, my wife and I can now focus on whats strong in our marriage versus whats wrong; the positive sentiment overrides versus negative sentiment overrides",
      verifiedBadge: true,
      subtitle: "Fortify Attendant",
    },
  ];

  function handleBackgroundVideoAutoplay() {
    setTimeout(() => {
      if (
        backgroundVideo !== undefined &&
        backgroundVideo.value !== undefined
      ) {
        backgroundVideo.value?.play();
      }
    }, 5000);
  }

  function initSwiper() {
    const testimonialSwiper: swiperElement =
      document.getElementById(sliderId);

    const swiperOptions: SwiperOptions = {
      ...commonSwiperParams,
      effect: "coverflow",
      coverflowEffect: {
        modifier: 1,
        slideShadows: false,
        stretch: -50,
        rotate: 10,
      },
      loopedSlides: 3,
      slidesPerView: "auto",
      centeredSlides: true,
    };

    if (testimonialSwiper !== null) {
      Object.assign(testimonialSwiper, swiperOptions);

      testimonialSwiper.initialize();
    }
  }

  onMounted(() => {
    handleBackgroundVideoAutoplay();
    initSwiper();
  });
</script>

<template>
  <section
    class="tw-mt-8 tw-flex tw-justify-center tw-items-center testimonials tw-relative tw-bg-white tw-overflow-hidden"
  >
    <div
      class="tw-hidden w-transition-all tw-duration-1000 tw-ease-in-out testimonials__video tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-h-full tw-opacity-0 lg:tw-block"
    >
      <video
        ref="backgroundVideo"
        :src="videoUrl"
        muted
        loop
        id="backgroundVideo"
        class="tw-w-full tw-h-full"
      ></video>
    </div>
    <div class="tw-pb-16 testimonials tw-pt-5 testimonials__inner">
      <div
        class="tw-text-left testimonials__inner__title tw-container tw-px-4 tw-mx-auto"
      >
        <h5
          class="tw-pb-5 xl:tw-text-3xl tw-font-light tw-text-lg lg:tw-text-xl"
        >
          Thought we'd share what our friends think.
        </h5>
        <h1
          class="xl:tw-text-7xl tw-italic tw-font-semibold tw-text-solid tw-pb-10 lg:tw-text-6xl tw-text-4xl"
        >
          Testimonials
        </h1>
      </div>
      <div
        class="tw-pt-5 tw-justify-center testimonials__inner__list tw-h-full tw-relative"
      >
        <swiper-container
          :id="sliderId"
          init="false"
          class="testimonials__inner__list__container tw-w-full tw-relative tw-flex tw-h-screen lg:h-[50vh]"
        >
          <swiper-slide
            v-for="(testimony, i) in testimonials"
            :key="i"
            class="tw-rounded-lg tw-bg-white tw-shadow-2xl tw-relative tw-flex tw-items-center tw-flex-col tw-py-12 tw-px-8 tw-text-center testimonials__inner__list__container__item tw-w-[300px] tw-h-[80vh] lg:tw-h-[65vh]"
          >
            <span
              class="tw-mb-8 tw-rounded-full tw-shadow-2xl tw-bg-white tw-flex tw-items-center tw-justify-center tw-w-14 tw-h-14 tw-shrink-0"
              :style="{
                backgroundImage: `url(${'/imgs/icons/user-placeholder.jpg'})`,
              }"
            ></span>

            <figcaption>
              <h4
                v-if="false"
                class="tw-pb-4 tw-text-2xl tw-font-normal tw-text-solid"
              >
                {{ testimony.name }}
              </h4>
              <h4
                v-if="testimony.subtitle && false"
                class="tw-flex tw-items-center tw-justify-center tw-pb-10 tw-text-accent"
              >
                {{ testimony.subtitle }}
                <Material
                  v-if="testimony.verifiedBadge"
                  icon="verified"
                  class="tw-pl-2"
                />
              </h4>
              <p class="tw-text-gray-600 tw-pb-7">
                {{ testimony.comment }}
              </p>
            </figcaption>
          </swiper-slide>
        </swiper-container>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  .testimonials {
    &:hover {
      .testimonials__video {
        opacity: 0.1;
      }
    }
    &__video {
      video {
        transform: translateY(0px) scale(1.5);
      }
    }
    &__inner {
      &__list {
        &::before {
          content: "";
          position: absolute;
          top: 9rem;
          right: 0;
          left: 0;
          height: 1px;
          background-color: $k-accent-hex;
        }
        &__container {
          // height: 70vh;
          &__item {
            width: 300px !important;
            // height: 60vh !important;
            span {
              @include bgStyles;
            }
          }
        }
      }
    }
  }
</style>
