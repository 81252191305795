import moment from "moment";

// Extend the Moment interface to include your custom function
declare module "moment" {
  interface Moment {
    formatDMY(): string;
  }
}

// Extend the Moment prototype
moment.fn.formatDMY = function (): string {
  return this.format("DD MMM, YYYY");
};

export default moment;
