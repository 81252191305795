export interface RouteItem {
  path: string;
  name: string;
  title: string;
}

const Routes: { [k: string]: RouteItem } = {
  HOME: {
    path: "/",
    name: "home",
    title: "Home",
  },
  INNER: {
    path: "/inner",
    name: "inner",
    title: "Inner Page",
  },
  SERVICES: {
    path: "/services",
    name: "services",
    title: "Services",
  },
  FAMILY_LIFE_EDUCATION: {
    path: "/services/family-life-education",
    name: "family-life-education",
    title: "Family Life Education",
  },
  FAMILY_LIFE_EDUCATION_ITEM: {
    path: "/services/family-life-education/:id",
    name: "family-life-education-item",
    title: "Family Life Education Item",
  },
  SERVICES_SUPPORT: {
    path: "/services/support",
    name: "services-support",
    title: "Services Support",
  },
  SERVICES_SUPPORT_ITEM: {
    path: "/services/support/:id",
    name: "services-support-item",
    title: "Services Support Item",
  },
  GALLERY: {
    path: "/gallery",
    name: "gallery",
    title: "Gallery",
  },
  BLOG: {
    path: "/blog",
    name: "blog",
    title: "Blog",
  },
  BLOG_CONTENT: {
    path: "/blog",
    name: "blog-content",
    title: "Blog Item",
  },
  PHOTOS: {
    path: "/gallery/photos",
    name: "gallery-photos",
    title: "Photos",
  },
  VIDEOS: {
    path: "/gallery/videos",
    name: "gallery-videos",
    title: "Videos",
  },
};

export default Routes;
