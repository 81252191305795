<script setup lang="ts">
  import { ref, watch, onMounted, computed } from "vue";
  import { useRoute } from "vue-router";

  import Routes from "@/enums/Routes";
  import router from "@/router";

  const route = useRoute();
  const path = computed(() => route.path as string);
  const pageId = computed(() => route.params.id as string);

  const activeTab = ref("");
  const tabs = ref([
    {
      title: "Educational Empowerment (Scholarships)",
      link: `${Routes.SERVICES_SUPPORT.path}/educational-empowerment`,
    },
    {
      title: "Social Support for Single Women",
      link: `${Routes.SERVICES_SUPPORT.path}/social-support-for-single-women`,
    },
  ]);

  function setActiveTab() {
    activeTab.value = path.value;
  }

  watch(pageId, () => {
    setActiveTab();
  });

  watch(activeTab, (val) => {
    router.push(val);
  });

  onMounted(() => {
    setActiveTab();
  });
</script>

<template>
  <div class="inner-page">
    <div class="inner-page__inner">
      <div class="tw-my-8 tw-container tw-px-4 tw-mx-auto">
        <ElTabs tab-position="top" v-model="activeTab">
          <ElTabPane
            v-for="(tab, i) in tabs"
            :key="i"
            :label="tab.title"
            :name="tab.link"
          >
            <RouterView v-if="activeTab === tab.link"></RouterView>
          </ElTabPane>
        </ElTabs>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .inner-page {
    &__inner {
      padding-top: $k-nav-height;
      .banner {
        @include bgImageDefault("");
      }
    }
  }
</style>
