<script setup lang="ts">
  import Routes from "@/enums/Routes";

  const featuredImage = "/imgs/bgs/couple-3.jpg";
</script>

<template>
  <section class="tw-mb-5 welcome">
    <div
      class="tw-container welcome__inner tw-mx-auto tw-flex tw-flex-col tw-p-4 tw-pt-8 lg:tw-flex-row lg:tw-pb-10 xl:tw-pb-16 lg:tw-pt-0"
    >
      <main
        class="welcome__inner__main lg:tw-w-1/2 lg:tw-pr-8 xl:tw-pr-36 lg:tw-py-16 xl:tw-py-28"
      >
        <div class="welcome__inner__main__item">
          <h5
            class="tw-text-lg tw-font-light tw-pb-2 lg:tw-text-xl xl:tw-text-3xl"
          >
            Welcome to
          </h5>
          <h1
            class="tw-text-4xl tw-italic tw-font-semibold tw-text-solid lg:tw-text-6xl xl:tw-text-8xl"
          >
            kḥeḥseḏ!
          </h1>

          <div
            class="tw-bg-primary tw-my-8 tw-h-[50vh] tw-border-b-8 tw-border-accent welcome__inner__main__item__image lg:tw-hidden feature-image"
            :style="{
              backgroundImage: `url(${featuredImage})`,
            }"
          ></div>

          <p class="tw-leading-loose lg:tw-pt-6">
            <span class="tw-font-bold tw-italic tw-text-solid">
              Pronounced kheh'-sed.
            </span>
            Khehsed Inc. was established in 2023 . It exists to help
            families and communities experience God’s grace, mercy and
            loving kindness through Family life relationship Education to
            faith and community organizations, Educational Empowerment for
            indigent persons, and Social Support for single parents.
          </p>

          <div class="tw-pt-6 tw-flex tw-gap-4">
            <ElButton type="primary">
              <RouterLink :to="`${Routes.INNER.path}/brief-history`">
                Learn More
              </RouterLink>
            </ElButton>
            <ElButton round v-if="false">
              <Material icon="play_circle" />
            </ElButton>
          </div>
        </div>
      </main>

      <div
        class="tw-hidden tw-shadow-lg tw-rounded-b-lg tw-bg-primary tw-p-8 lg:tw-block tw-w-1/2 feature-image"
        :style="{
          backgroundImage: `url(${featuredImage})`,
        }"
      ></div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  .welcome {
    .feature-image {
      @include bgStyles;
    }
  }
</style>

<style lang="scss">
  .swiper.swiper-coverflow {
    overflow: visible !important;
  }
</style>
