import Api from "@/utils/api";

interface KeyValuePairs {
  [key: string]: any;
}

interface InnerPage {
  title: string;
  banner?: string;
  content: string;
}

interface OfferingPage {
  title: string;
  banners?: string[];
  content: string;
}

export interface PhotoItem {
  thumbnail?: string | null;
  small: string | null;
  medium?: string | null;
  large?: string | null;
  updated?: string | null;
}

interface PhotosPage {
  title: string;
  banner: string;
  photos: PhotoItem[];
}

export interface VideoItem {
  source: string;
  title: string;
  videoId: string;
  updatedAt: string;
}

interface VideosPage {
  title: string;
  banner: string;
  videos: VideoItem[];
}

type ImageFormat = {
  formats: {
    small: {
      url: string;
    };
    thumbnail: {
      url: string;
    };
    medium: {
      url: string;
    };
    large: {
      url: string;
    };
  };
};

export interface BlogItem {
  id: string;
  content: string;
  likes: number;
  title: string;
  titleBold: string;
  updatedAt: string;
  titleLight: string;
  banner: ImageFormat;
  featuredImage: ImageFormat;
}

interface BlogPage {
  title: string;
  banner: string;
  blogs: BlogItem[];
}

const aboutFamilyLifeEnrichment: InnerPage = {
  title: "Family Life Enrichment",
  banner:
    "https://res.cloudinary.com/devkhoded/image/upload/v1599481326/banner_collage_2_9fe692cffd.jpg",
  content: `<p>
              Khehsed Inc. was established in 2023 . It exists to help families and
              communities experience God’s grace, mercy and loving kindness
              through:
            </p>
            <ul>
              <li>
                Family life relationship Education to faith and community
                organizations
              </li>
              <li>Educational Empowerment for indigent persons</li>
              <li>Social Support for single parents</li>
            </ul>
            <hr />
            <h4>
              Family life relationship Education to faith and community
              organizations
            </h4>
            <p>Includes:</p>
            <ul>
              <li>Retreats/Get Aways</li>
              <li>Family life Conferences/Summits</li>
              <li>Seminars/Workshops</li>
              <li>Couples Group Coaching</li>
            </ul>
            <p>Couples learn how to achieve:</p>
            <ul>
              <li>Work – life harmony</li>
              <li>Win-win conflict resolution outcomes</li>
              <li>Effective expression of your intimate needs</li>
              <li>Build a fondness and admiration system</li>
              <li>Prevention of emotional numbness</li>
              <li>Move from "I love you" to "I trust you"</li>
              <li>Secure a financial future</li>
              <li>Leave a legacy</li>
              <li>
                Optimize the impact of technology and social media on
                relationships
              </li>
            </ul>
            <p>Parents learn how to achieve:</p>
            <ul>
              <li>G.R.A.C.E approach to parenting</li>
              <li>Parenting to parent-partnerships with Adult Children</li>
            </ul>
            <p>Teenagers learn how to achieve:</p>
            <ul>
              <li>Wholesome Sexuality</li>
              <li>Successfully navigate Parent -Child relationships</li>
              <li>Bridge Cultural gaps</li>
              <li>Cultivate value-based relationships.</li>
            </ul>
            <p>Churches are empowered through:</p>
            <ul>
              <li>Certified Marriage Mentorship Program</li>
              <li>Set up of Family Life Department</li>
              <li>Trauma and Compassion informed ministry training.</li>
            </ul>

            <hr />
            <h4>Educational Empowerment for indigent persons</h4>
            <p>
              The education of referred Indigents persons in Nigeria is
              supported through solicited scholarships and donations.
            </p>

            <hr />
            <h4>Social Support for single parents</h4>
            <p>
              We provide social support for referred indigent single
              parents in Nigeria.
            </p>`,
};

const familyLifeEducation: InnerPage = {
  title: "Family Life Education",
  banner:
    "https://res.cloudinary.com/devkhoded/image/upload/v1599481326/banner_collage_2_9fe692cffd.jpg",
  content: "",
};

const history: InnerPage = {
  title: "Brief History",
  banner:
    "https://res.cloudinary.com/devkhoded/image/upload/v1599480242/banner_collage_6c963a2285.jpg",
  content: `
  <blockquote>
        <p>Israel and Augusta, aka Dele and Yetunde are called the dynamic duo. Their 40 years of friendship started as University students which later grew into a romantic relationship that led to marriage on April 20th 1987.</p>
      </blockquote>
      <figure>
        <blockquote>
          <p>Israel’s sense of humor cracks me up so bad that I can’t stay angry with him for too long!</p>
        </blockquote>
        <figcaption>Augusta</figcaption>
      </figure>
      <figure>
        <blockquote>
          <p>Augusta keeps me on my toes all the time. She’s so pretty and so smart, yet painfully hardworking. No slacking around her. She puts a playful twist and zest to everything she does. I mean, everything</p>
        </blockquote>
        <figcaption>Israel</figcaption>
      </figure>
      <p>We share our warmth and authenticity with people we meet and are passionate about helping individuals and families enjoy fulfillment in their relationships. Augusta Yetunde Olaore Ph.D. is a Social Work practitioner and educator with over 20 years of practice experience with children and families across the globe. She is passionate about utilizing biblical principles to undergird her professional knowledge base for empowering individual families and churches for optimal relationships. She is also a partner in the Design Thinkers Group, USA, where she utilizes principles of Human-centered design to solve complex challenges in the workplace. Israel Bamidele Olaore Ph, D. is an ordained minister of the gospel with over 30 years’ experience in Pastoral ministry and educational leadership. He is driven with the purpose of equipping people to become God’s Solution persons (GSP) through leadership mentoring. Israel is particularly committed to mentoring men in their roles as husbands and fathers. We are Generation X parents of 4 sons, daughters- in- love and grandchildren. We enjoy traveling, music, playing word games, movies and meeting new people.</p>
  `,
};

const coupleEnrichment: OfferingPage = {
  title: "Couples Enrichment",
  banners: [
    "https://res.cloudinary.com/devkhoded/image/upload/v1593789608/offering_4_a9b910830c.jpg",
    "https://res.cloudinary.com/devkhoded/image/upload/v1593789611/olaores_sm_d28b875b1e.jpg",
    "https://res.cloudinary.com/devkhoded/image/upload/v1593789617/team_c5903444d0.jpg",
  ],
  content: `<blockquote>
        <p>Having said “ I do” to each other in public, now you wonder privately, “ how do I do this thing called marriage?” Gain the skills, attitudes and values needed to navigate marital relationships with ease.</p>
      </blockquote>
      <p>Having said “ I do” to each other in public, now you wonder privately, “ how do I do this thing called marriage?” Where is the happy ever after ? We come into marriage with lots of hopes and dreams. We look forward to a life of marital bliss, then we have the first fight and it hurts. What happened ? is it really for better or for worse? We soon discover that being married is actually a bed of roses…with the softness of marital love and the thorns of human imperfection. Marriages that work require work. Here at Savaye, we come along the side of our couples to cheer them on and provide objective insights for better understanding.</p>
      <h4>Skill-sets we offer</h4>
      <ul>
        <li>Family Systems Engineering modules</li>
        <li>Family Immigration System</li>
        <li>From wedding to Welding – becoming one</li>
        <li>Keeping it fresh and Sizzling</li>
        <li>Love and Respect</li>
        <li>8 Habits of Highly Successful Marriages</li>
        <li>Raising your own Nation</li>
        <li>GRACE Approach to family life</li>
        <li>Seasons of Marriage</li>
        <li>In-Laws are from Jupiter</li>
        <li>Making love and doing love</li>
        <li>Into- me- see</li>
        <p>Skills, attitudes, and values to navigate marital relationships with ease</p>
      </ul>
      <h4>What you can expect from us</h4>
      <ul>
        <li>Apt acknowledgement and professional response to your booking requests, calls, and email messages.</li>
        <li>A collaborative co-creation process prior to your event, so we can better align your needs with our services.</li>
        <li>In-person and/or virtual options of programming.</li>
        <li>Broadcasting your event on our social networks subject to your approval.</li>
        <li>A multi-media interactive and engaging presentation aimed at achieving the desired outcomes you want with your audience.</li>
        <li>We will be culturally appropriate, authentic, and non-judgmental in our presentations.</li>
        <li>We provide a feedback loop with the audience and you to ascertain that your goals and expectations of the event were achieved.</li>
      </ul>`,
};

const singlesEnrichment: OfferingPage = {
  title: "Singles Enrichment",
  banners: [
    "https://res.cloudinary.com/devkhoded/image/upload/v1593789617/team_c5903444d0.jpg",
  ],
  content: `<blockquote>
        <p>Savaye provide skills, promote attitudes and present values that makes navigating singleness less confusing</p>
      </blockquote>
      <p>Is marriage for everybody? How do I know if I am ready for marriage? How do I know who I am supposed to marry? Who should do the pursuit? How can I be confident in a relationship?How many people should I date at the same time? How do we handle sexual temptations ? As a single, these questions and others come to mind at different times. Having worked with singles for over 30 years, we at Savaye provide skills, promote attitudes and present values that makes navigating singleness less confusing.</p>
      <h4>Classes we offer</h4>
      <ul>
        <li>The Singleness Quadrant</li>
        <li>Singleness and contemporary Trends</li>
        <li>Being and Selecting a purpose mate</li>
        <li>Single, to be or not to be?</li>
        <li>Shopping Tips for Singles</li>
        <p>Skills attitudes and values for wholesome relationships</p>
      </ul>
      <h4>What you can expect from us</h4>
      <ul>
        <li>Apt acknowledgement and professional response to your booking requests, calls and email messages.</li>
        <li>A collaborative co-creation process prior to your event, so we can better align your needs with our services.</li>
        <li>In – Person and/or virtual options of programming.</li>
        <li>Broadcasting your event on our social networks subject to your approval.</li>
        <li>A multi-media interactive and, engaging, fun presentation aimed at achieving the desired outcomes you want with your audience.</li>
        <li>We will be culturally appropriate, authentic and non-judgmental in our presentations.</li>
        <li>We provide a feedback loop with the audience and you to ascertain that your goals and expectations of the event were achieved.</li>
      </ul>`,
};

const parentsEnrichment: OfferingPage = {
  title: "Parents Enrichment",
  banners: [
    "https://res.cloudinary.com/devkhoded/image/upload/v1593789617/team_c5903444d0.jpg",
  ],
  content: `<blockquote>
        <p>Is parenting a job or a calling? How do we balance parenting and marriage? Must we have children? What if I have made mistakes with my child that I now regret? Why are my children so different?</p>
      </blockquote>
      <p>Is parenting a job or a calling? How do we balance parenting and marriage? Must we have children? What if I have made mistakes with my child that I now regret? Why are my children so different? What are the dos and don’ts of parenting? How often do we wish that each child came into the world holding their own user manual! Over the years as parents and family life practitioners, we have discovered that there is actually a user manual for each child. parenting Enrichment is aboiut gaining understanding of our own user manual, that of our children and providing the right environment to help our children thrive inspite of the challenges of life. We provide tools and co create strategies that will enhance parent-child relationships.</p>
      <h4>Skills attitudes and values for parents enrichment</h4>
      <ul>
        <li>Out of the Box Parenting</li>
        <li>Fathers Grace</li>
        <li>Father’s Blessing</li>
        <li>Fatherhood is Learnt</li>
        <li>GRACE approach to parenting</li>
        <li>Father Love</li>
        <li>Fatherhood and Priesthood</li>
        <li>Challenges of Fatherhood</li>
        <li>Releasing Your Children</li>
        <li>Parenting Adult Children</li>
        <p>You become a better parent after getting these skills</p>
      </ul>
      <h4>What you can expect from us</h4>
      <ul>
        <li>Apt acknowledgement and professional response to your booking requests, calls and email messages.</li>
        <li>A collaborative co-creation process prior to your event, so we can better align your needs with our services.</li>
        <li>In – Person and/or virtual options of programming.</li>
        <li>Broadcasting your event on our social networks subject to your approval.</li>
        <li>A multi-media interactive and, engaging, fun presentation aimed at achieving the desired outcomes you want with your audience.</li>
        <li>We will be culturally appropriate, authentic and non-judgmental in our presentations.</li>
        <li>We provide a feedback loop with the audience and you to ascertain that your goals and expectations of the event were achieved.</li>
      </ul>`,
};

const teensEnrichment: OfferingPage = {
  title: "Teens Enrichment",
  banners: [
    "https://res.cloudinary.com/devkhoded/image/upload/v1593789617/team_c5903444d0.jpg",
  ],
  content: `<blockquote>
        <p>Teenage years are not terminal ( Though it may feel like it sometimes!). It is like the pupal stage between the gregarious childhood caterpillar and the beautiful butterfly.</p>
      </blockquote>
      <p>Teen years are both exciting and challenging. A block of six to eight years is dedicated to transitioning from childhood to adulthood. We deal with changes in our bodies and in our minds. We are confronted with making choices about relationships we have taken for granted and wonder who is more important, friends or family? Well the good news is that, Teenage years are not terminal (Though it may feel like it sometimes!). It is like the pupal stage between the gregarious childhood caterpillar and the beautiful butterfly. With the accurate information and support, one can emerge out of adolescence with a wholesome of identity and a sense of belonging, ready to take on the adult world.</p>
      <h4>Points on Teens Enrichment</h4>
      <ul>
        <li>Becoming a Puresexual</li>
        <li>Sexuality and Friendship</li>
        <li>Your GCE</li>
        <li>Safe and Sane with Parents</li>
        <li>Celebrating Chastity Program</li>
        <li>Pretty Ugly</li>
        <li>Self-acceptance</li>
        <li>Career Choice</li>
        <li>Safe and Sane with Siblings</li>
        <li>Y.E.S. Rites of Passage Training and Ceremony</li>
        <p>Skills attitudes and values for self- awareness and engaging meaningful relationships with peers and family</p>
      </ul>
      <h4>What you can expect from us</h4>
      <ul>
        <li>Apt acknowledgement and professional response to your booking requests, calls and email messages.</li>
        <li>A collaborative co-creation process prior to your event, so we can better align your needs with our services.</li>
        <li>In – Person and/or virtual options of programming.</li>
        <li>Broadcasting your event on our social networks subject to your approval.</li>
        <li>A multi-media interactive and, engaging, fun presentation aimed at achieving the desired outcomes you want with your audience.</li>
        <li>We will be culturally appropriate, authentic and non-judgmental in our presentations.</li>
        <li>We provide a feedback loop with the audience and you to ascertain that your goals and expectations of the event were achieved.</li>
      </ul>`,
};

const workplaceEnrichment: OfferingPage = {
  title: "Workplace Enrichment",
  banners: [
    "https://res.cloudinary.com/devkhoded/image/upload/v1593789617/team_c5903444d0.jpg",
  ],
  content: `<blockquote>
        <p>A third of our waking hours is spent at work. Therefore workplace relationship health is vital for wholesome wellbeing. Savaye provides and promotes skills attitudes and values for organizations to become more human centered.</p>
      </blockquote>
      <p>A third of our waking hours is spent at work. Therefore workplace relationship health is vital for wholesome wellbeing. Savaye provides and promotes skills attitudes and values for organizations to become more human centered. Our trainings seek to infuse soft skills such as empathy, affirmation, appreciation, team spirit etc into the workspace thus enhancing a healthy emotional climate for optimal productivity, innovation and achievement of organizational goals</p>
      <h4>Focus</h4>
      <ul>
        <li>The Me We Mindset</li>
        <li>Emotional Intelligence in the workplace</li>

        <p>Skills, attitudes and values for managing workplace relationships. Team building, cultural competence, inclusiveness</p>
      </ul>
      <h4>What you can expect from us</h4>
      <ul>
        <li>Apt acknowledgement and professional response to your booking requests, calls and email messages.</li>
        <li>A collaborative co-creation process prior to your event, so we can better align your needs with our services.</li>
        <li>In – Person and/or virtual options of programming.</li>
        <li>Broadcasting your event on our social networks subject to your approval.</li>
        <li>A multi-media interactive and, engaging, fun presentation aimed at achieving the desired outcomes you want with your audience.</li>
        <li>We will be culturally appropriate, authentic and non-judgmental in our presentations.</li>
        <li>We provide a feedback loop with the audience and you to ascertain that your goals and expectations of the event were achieved.</li>
      </ul>`,
};

const churchesEnrichment: OfferingPage = {
  title: "Churches Enrichment",
  banners: [
    "https://res.cloudinary.com/devkhoded/image/upload/v1593789617/team_c5903444d0.jpg",
  ],
  content: `<blockquote>
        <p>Historically, the temple is often the target of the invading enemies of Israel. Why so? This is because the temple holds the highest concentration of precious stones, gold, silver, bronze and etc. used for service.</p>
      </blockquote>
      <p>Historically, the temple is often the target of the invading enemies of Israel. Why so? This is because the temple holds the highest concentration of precious stones, gold, silver, bronze and etc. used for service. Paul, the apostle said that believers are lively stones interconnected and bonded together to make up the temple of God which is the church. It is therefore important that churches are rightly tooled to keep the membership tightly connected in wholesome relationships. It is also important that believers are equipped with skills, attitudes and values for impactful ministries to their communities. Savaye, exists to provide and promote Christ centered skills, attitudes and values such as non judgemental acceptance, empathy, compassion for thriving churches.</p>
      <h4>Offers</h4>
      <ul>
        <li>C.A.R.E Training</li>
        <li>Church Emotional Closeness in Social Distancing</li>
        <li>Caring for the Body of Christ: J o A Model</li>
        <li>Me We Mindset for Churches</li>
        <li>Enhancing Ownership and Complementarity of Church Membership</li>
        <li>Raising the EQ of Your Church</li>
        <li>Jabez Conference</li>
        <li>Safe Churches to Brave Churches</li>

        <p>Our programs are fun, hands-on, interactive and highly engaging. We integrate biblical principles into best practices in relationship building techniques based on a multidisciplinary knowledge base.</p>
      </ul>
      <h4>What you can expect from us</h4>
      <ul>
        <li>Apt acknowledgement and professional response to your booking requests, calls and email messages.</li>
        <li>A collaborative co-creation process prior to your event, so we can better align your needs with our services.</li>
        <li>In – Person and/or virtual options of programming.</li>
        <li>Broadcasting your event on our social networks subject to your approval.</li>
        <li>A multi-media interactive and, engaging, fun presentation aimed at achieving the desired outcomes you want with your audience.</li>
        <li>We will be culturally appropriate, authentic and non-judgmental in our presentations.</li>
        <li>We provide a feedback loop with the audience and you to ascertain that your goals and expectations of the event were achieved.</li>
      </ul>`,
};

const photosPage: PhotosPage = {
  title: "Photos",
  banner: "/imgs/bgs/people-bg.jpg",
  photos: [
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1593789612/small_olaores_sm_d28b875b1e.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1593789611/medium_olaores_sm_d28b875b1e.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1593789611/thumbnail_olaores_sm_d28b875b1e.jpg",
      large: null,
      updated: "2020-09-06T18:00:14.793Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599411372/small_team_2_1bdbafbdaa.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599411372/medium_team_2_1bdbafbdaa.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599411371/thumbnail_team_2_1bdbafbdaa.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599411371/large_team_2_1bdbafbdaa.jpg",
      updated: "2020-09-06T18:00:14.791Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599411370/small_out_2b21291b91.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599411370/medium_out_2b21291b91.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599411369/thumbnail_out_2b21291b91.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599411370/large_out_2b21291b91.jpg",
      updated: "2020-09-06T18:00:14.828Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599411370/small_team_60ee4e6d47.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599411370/medium_team_60ee4e6d47.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599411369/thumbnail_team_60ee4e6d47.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599411369/large_team_60ee4e6d47.jpg",
      updated: "2020-09-06T18:00:14.800Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599411370/small_me_f93482b2cb.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599411370/medium_me_f93482b2cb.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599411369/thumbnail_me_f93482b2cb.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599411369/large_me_f93482b2cb.jpg",
      updated: "2020-09-06T18:00:15.687Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599411370/small_task_e7be8e27d9.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599411369/medium_task_e7be8e27d9.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599411368/thumbnail_task_e7be8e27d9.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599411369/large_task_e7be8e27d9.jpg",
      updated: "2020-09-06T18:00:14.780Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599411369/small_board_52023a43f6.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599411369/medium_board_52023a43f6.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599411367/thumbnail_board_52023a43f6.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599411368/large_board_52023a43f6.jpg",
      updated: "2020-09-06T18:00:14.831Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599411369/small_Balloons_a1b066d4a8.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599411369/medium_Balloons_a1b066d4a8.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599411367/thumbnail_Balloons_a1b066d4a8.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599411368/large_Balloons_a1b066d4a8.jpg",
      updated: "2020-09-06T18:00:14.827Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1593789609/small_offering_4_a9b910830c.jpg",
      medium: null,
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1593789609/thumbnail_offering_4_a9b910830c.jpg",
      large: null,
      updated: "2020-09-06T18:00:14.802Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1593789618/small_team_c5903444d0.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1593789618/medium_team_c5903444d0.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1593789618/thumbnail_team_c5903444d0.jpg",
      large: null,
      updated: "2020-09-06T18:00:15.685Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1593789607/small_family_870465de70.jpg",
      medium: null,
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1593789606/thumbnail_family_870465de70.jpg",
      large: null,
      updated: "2020-09-06T18:00:14.811Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1593789611/small_olaores_529c81a68d.jpg",
      medium: null,
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1593789611/thumbnail_olaores_529c81a68d.jpg",
      large: null,
      updated: "2020-09-06T18:00:15.696Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1593789612/small_offering_6_f91c5fee1c.jpg",
      medium: null,
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1593789612/thumbnail_offering_6_f91c5fee1c.jpg",
      large: null,
      updated: "2020-09-06T18:00:15.691Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1593789609/small_offering_2_70895cf32c.jpg",
      medium: null,
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1593789608/thumbnail_offering_2_70895cf32c.jpg",
      large: null,
      updated: "2020-09-06T18:00:14.826Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413561/small_yel_8586103c89.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413561/medium_yel_8586103c89.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413560/thumbnail_yel_8586103c89.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413561/large_yel_8586103c89.jpg",
      updated: "2020-09-06T18:00:14.816Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413562/small_Ty_f75f48cce3.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413561/medium_Ty_f75f48cce3.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413560/thumbnail_Ty_f75f48cce3.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413561/large_Ty_f75f48cce3.jpg",
      updated: "2020-09-06T18:00:14.836Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413561/small_t_2a60d8597d.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413560/medium_t_2a60d8597d.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413560/thumbnail_t_2a60d8597d.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413560/large_t_2a60d8597d.jpg",
      updated: "2020-09-06T18:00:14.808Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413559/small_st_2327e891bd.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413559/medium_st_2327e891bd.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413558/thumbnail_st_2327e891bd.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413559/large_st_2327e891bd.jpg",
      updated: "2020-09-06T18:00:14.787Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413559/small_smm_c5d1adc730.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413559/medium_smm_c5d1adc730.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413558/thumbnail_smm_c5d1adc730.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413559/large_smm_c5d1adc730.jpg",
      updated: "2020-09-06T18:00:14.807Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413559/small_si_eb5b4425b8.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413558/medium_si_eb5b4425b8.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413557/thumbnail_si_eb5b4425b8.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413558/large_si_eb5b4425b8.jpg",
      updated: "2020-09-06T18:00:14.810Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413559/small_sm_4c5a59dce3.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413558/medium_sm_4c5a59dce3.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413557/thumbnail_sm_4c5a59dce3.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413558/large_sm_4c5a59dce3.jpg",
      updated: "2020-09-06T18:00:15.708Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413559/small_s_2fccb1f609.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413558/medium_s_2fccb1f609.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413557/thumbnail_s_2fccb1f609.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413558/large_s_2fccb1f609.jpg",
      updated: "2020-09-06T18:00:15.700Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413558/small_sign_ab772d1e3a.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413558/medium_sign_ab772d1e3a.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413557/thumbnail_sign_ab772d1e3a.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413557/large_sign_ab772d1e3a.jpg",
      updated: "2020-09-06T18:00:14.814Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413557/small_pl_2ff34e69e7.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413556/medium_pl_2ff34e69e7.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413555/thumbnail_pl_2ff34e69e7.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413556/large_pl_2ff34e69e7.jpg",
      updated: "2020-09-06T18:00:14.834Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413557/small_rw_6afde701d1.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413556/medium_rw_6afde701d1.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413556/thumbnail_rw_6afde701d1.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413556/large_rw_6afde701d1.jpg",
      updated: "2020-09-06T18:00:14.790Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413556/small_ph_6d5e820435.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413556/medium_ph_6d5e820435.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413555/thumbnail_ph_6d5e820435.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413555/large_ph_6d5e820435.jpg",
      updated: "2020-09-06T18:00:14.830Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413556/small_mon_b5b6d380e1.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413555/medium_mon_b5b6d380e1.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413554/thumbnail_mon_b5b6d380e1.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413555/large_mon_b5b6d380e1.jpg",
      updated: "2020-09-06T18:00:14.824Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413555/small_p_a048040579.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413555/medium_p_a048040579.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413554/thumbnail_p_a048040579.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413555/large_p_a048040579.jpg",
      updated: "2020-09-06T18:00:15.690Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413555/small_op_b5206d0e28.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413555/medium_op_b5206d0e28.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413554/thumbnail_op_b5206d0e28.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413555/large_op_b5206d0e28.jpg",
      updated: "2020-09-06T18:00:14.813Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413554/small_h_05e6e9aedd.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413553/medium_h_05e6e9aedd.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413553/thumbnail_h_05e6e9aedd.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413553/large_h_05e6e9aedd.jpg",
      updated: "2020-09-06T18:00:14.818Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413553/small_gay_42db74b625.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413553/medium_gay_42db74b625.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413552/thumbnail_gay_42db74b625.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413553/large_gay_42db74b625.jpg",
      updated: "2020-09-06T18:00:14.785Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413553/small_m_ab7d1a2b94.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413552/medium_m_ab7d1a2b94.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413551/thumbnail_m_ab7d1a2b94.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413552/large_m_ab7d1a2b94.jpg",
      updated: "2020-09-06T18:00:15.710Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413553/small_g_b39f760dda.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413552/medium_g_b39f760dda.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413552/thumbnail_g_b39f760dda.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413552/large_g_b39f760dda.jpg",
      updated: "2020-09-06T18:00:14.805Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413552/small_mn_7d853c8a6f.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413552/medium_mn_7d853c8a6f.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413551/thumbnail_mn_7d853c8a6f.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413551/large_mn_7d853c8a6f.jpg",
      updated: "2020-09-06T18:00:15.703Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413552/small_ma_2928826b9f.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413552/medium_ma_2928826b9f.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413551/thumbnail_ma_2928826b9f.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413551/large_ma_2928826b9f.jpg",
      updated: "2020-09-06T18:00:15.701Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413549/small_fr_fcda5c14ef.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413549/medium_fr_fcda5c14ef.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413548/thumbnail_fr_fcda5c14ef.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413549/large_fr_fcda5c14ef.jpg",
      updated: "2020-09-06T18:00:14.788Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413549/small_c_484740d5cd.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413549/medium_c_484740d5cd.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413548/thumbnail_c_484740d5cd.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413548/large_c_484740d5cd.jpg",
      updated: "2020-09-06T18:00:14.821Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413549/small_fa_1bdd5040d1.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413549/medium_fa_1bdd5040d1.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413548/thumbnail_fa_1bdd5040d1.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413548/large_fa_1bdd5040d1.jpg",
      updated: "2020-09-06T18:00:14.823Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413549/small_b_f4d93996f2.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413548/medium_b_f4d93996f2.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413547/thumbnail_b_f4d93996f2.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413548/large_b_f4d93996f2.jpg",
      updated: "2020-09-06T18:00:14.819Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413548/small_be_c2c12e3d1c.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413548/medium_be_c2c12e3d1c.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413547/thumbnail_be_c2c12e3d1c.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413548/large_be_c2c12e3d1c.jpg",
      updated: "2020-09-06T18:00:15.695Z",
    },
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413548/small_bu_1f71f9cc36.jpg",
      medium:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413548/medium_bu_1f71f9cc36.jpg",
      thumbnail:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413547/thumbnail_bu_1f71f9cc36.jpg",
      large:
        "https://res.cloudinary.com/devkhoded/image/upload/v1599413548/large_bu_1f71f9cc36.jpg",
      updated: "2020-09-06T18:00:14.837Z",
    },
  ],
};

const innerPages: KeyValuePairs = {
  "about-family-life-enrichment": aboutFamilyLifeEnrichment,
  "brief-history": history,
  "family-life-education": familyLifeEducation,
};

const offeringPages: KeyValuePairs = {
  "couple-enrichment": coupleEnrichment,
  "singles-enrichment": singlesEnrichment,
  "parents-enrichment": parentsEnrichment,
  "teens-enrichment": teensEnrichment,
  "workplace-enrichment": workplaceEnrichment,
  "churches-enrichment": churchesEnrichment,
};

export function fetchInnerPage(id: string): Promise<InnerPage> {
  return new Promise((resolve, reject) => {
    const page = innerPages[id];
    if (page) {
      resolve(page);
    } else {
      reject("No page found");
    }
  });
}

export function fetchOfferingPage(id: string): Promise<OfferingPage> {
  return new Promise((resolve, reject) => {
    const page = offeringPages[id];
    if (page) {
      resolve(page);
    } else {
      reject("No page found");
    }
  });
}

export function fetchPhotos(): Promise<PhotosPage> {
  return new Promise((resolve) => {
    resolve(photosPage);
  });
}

export function fetchVideos(): Promise<VideosPage> {
  return new Promise((resolve, reject) => {
    Api.get(`savaye-videos?_sort=createdAt:desc`)
      .then((res) => {
        const data = res.data as VideoItem[];

        const videos: VideosPage = {
          title: "Videos",
          banner: "/imgs/bgs/people-bg.jpg",
          videos: data,
        };

        resolve(videos);
      })
      .catch(() => {
        reject("Cannot fetch videos");
      });
  });
}

export function fetchBlog(): Promise<BlogPage> {
  return new Promise((resolve, reject) => {
    Api.get(`blogposts?_sort=createdAt:desc`)
      .then((res) => {
        const data = res.data as BlogItem[];

        const blogs: BlogPage = {
          title: "Blog",
          banner: "/imgs/bgs/blog.jpg",
          blogs: data,
        };

        resolve(blogs);
      })
      .catch(() => {
        reject("Cannot fetch videos");
      });
  });
}

export function fetchBlogContent(id: string): Promise<BlogItem> {
  return new Promise((resolve, reject) => {
    Api.get(`blogposts/${id}`)
      .then((res) => {
        const data = res.data as BlogItem;

        resolve(data);
      })
      .catch(() => {
        reject("Cannot fetch videos");
      });
  });
}

export default {
  innerPages,
  offeringPages,
  fetchPhotos,
  fetchVideos,
  fetchBlog,
  fetchBlogContent,
};
