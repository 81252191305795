<script setup lang="ts">
  import { ref } from "vue";

  const form = ref<any>({
    select: [
      {
        value: "Option1",
        label: "Option1",
      },
    ],
  });
</script>

<template>
  <section class="tw-mb-5 tw-relative home-contact">
    <div
      class="tw-container tw-py-8 lg:tw-py-16 tw-flex tw-flex-wrap tw-px-4 tw-mx-auto"
    >
      <main
        class="tw-w-full lg:tw-w-1/2 tw-py-10 lg:tw-py-20 tw-flex lg:tw-items-center lg:tw-justify-center tw-flex-col"
      >
        <div>
          <h5
            class="tw-pb-5 xk:tw-text-3xl tw-font-light tw-text-lg lg:tw-text-xl"
          >
            Have you got any Questions?
          </h5>
          <h1
            class="tw-italic xl:tw-text-7xl tw-font-semibold tw-text-solid tw-drop-shadow-lg lg:tw-text-6xl tw-text-4xl"
          >
            Message Us.
          </h1>
        </div>
      </main>

      <div
        class="tw-shadow-lg tw-rounded-b-lg tw-font-semibold hover:tw-shadow-2xl tw-transition-shadow tw-duration-1000 tw-ease-in-out tw-w-full lg:tw-w-1/2"
      >
        <h4
          class="lg:tw-px-14 lg:tw-py-16 tw-text-base lg:tw-text-2xl tw-font-light tw-p-8"
        >
          We will be in touch as soon as we receive your message.
        </h4>

        <el-form
          :label-position="'top'"
          label-width="100px"
          :model="form"
          class="tw-grid tw-grid-cols-12 tw-gap-y-10 lg:tw-px-14 lg:tw-gap-x-4 lg:tw-pb-16 tw-p-8"
        >
          <el-form-item
            class="tw-col-span-12 lg:tw-col-span-6"
            label="First Name"
          >
            <el-input v-model="form.name" />
          </el-form-item>
          <el-form-item
            class="tw-col-span-12 lg:tw-col-span-6"
            label="Last Name"
          >
            <el-input v-model="form.region" />
          </el-form-item>
          <el-form-item
            class="tw-col-span-12 lg:tw-col-span-6"
            label="Email Address"
          >
            <el-input v-model="form.type" />
          </el-form-item>
          <el-form-item
            class="tw-col-span-12 lg:tw-col-span-6"
            label="Phone Number"
          >
            <el-input v-model="form.name" />
          </el-form-item>
          <el-form-item
            class="tw-col-span-12 lg:tw-col-span-6"
            label="Country"
          >
            <el-select
              v-model="form.selectOption"
              placeholder="Select Country"
            >
              <el-option
                v-for="item in form.select"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            class="tw-col-span-12 lg:tw-col-span-6"
            label="Message Type"
          >
            <el-select
              v-model="form.selectOption"
              placeholder="Select Message Type"
            >
              <el-option
                v-for="item in form.select"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            label="Message"
            prop="subscribe"
            class="tw-col-span-12"
          >
            <el-input v-model="form.subscribe" type="textarea" />
          </el-form-item>
          <el-form-item
            label="Subscribe to our Newsletters"
            prop="subscribe"
            class="tw-col-span-12"
          >
            <el-checkbox
              v-model="form.subscribe"
              label="We like to keep you updated about news and offers from Khehsed to you"
            />
          </el-form-item>
        </el-form>

        <div
          class="tw-px-14 tw-py-8 tw-border-t tw-border-gray-200 tw-grid tw-grid-cols-12 tw-gap-y-10"
        >
          <ElTooltip content="Coming soon...">
            <ElButton
              type="primary"
              class="tw-col-span-12 lg:tw-col-span-6"
              disabled
            >
              <Material icon="send" />
              Send to us!
            </ElButton>
          </ElTooltip>
        </div>
      </div>
    </div>
  </section>
</template>
<style lang="scss" scoped>
  .home-contact {
  }
</style>
