<script setup lang="ts">
  import { useQuery } from "@tanstack/vue-query";
  import { fetchVideos } from "@ENUMS/pages";

  const { isPending, isError, data, refetch } = useQuery({
    queryKey: ["videos"],
    queryFn: () => fetchVideos(),
    staleTime: Infinity,
  });
</script>

<template>
  <div class="videos">
    <div class="videos__inner">
      <InnerPageSkeleton v-if="isPending" />
      <PageError v-else-if="isError" :retry="refetch" />
      <template v-else-if="data">
        <div class="tw-container tw-px-4 tw-py-8 tw-mx-auto">
          <div>
            <h5 class="tw-font-bold tw-text-3xl">{{ data.title }}</h5>
          </div>
        </div>
        <div
          v-if="data.banner"
          class="banner tw-h-[50vh] tw-bg-primary slider sm:tw-h-[50vh] md:tw-h-[50vh] lg:tw-h-[50vh] xl:tw-h-[50vh] tw-relative"
          :style="{
            backgroundImage: `url(${data.banner})`,
          }"
        ></div>
        <div class="tw-my-8 tw-container tw-px-4 tw-mx-auto">
          <div class="tw-py-8 tw-mx-auto">
            <div
              class="tw-pt-5 tw-justify-center tw-flex tw-flex-wrap tw-relative before:tw-top-16 before:lg:tw-top-20 before:xl:tw-top-36"
            >
              <VideoPlayer
                v-for="(video, i) in data.videos"
                :key="i"
                :video="video"
              />
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .videos {
    &__inner {
      padding-top: $k-nav-height;
      .banner {
        @include bgImageDefault("");
      }
    }
  }
</style>
