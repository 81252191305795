import { PhotoItem } from "@ENUMS/pages";

interface KeyValuePairs {
  [key: string]: any;
}

interface ServicesSupport {
  title: string;
  banners: PhotoItem[];
  photos: PhotoItem[];
}

const educationalEmpowerment: ServicesSupport = {
  title: "Educational Empowerment",
  banners: [
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1593789608/offering_4_a9b910830c.jpg",
    },
  ],
  photos: [
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1593789608/offering_4_a9b910830c.jpg",
    },
  ],
};
const socialSupportForSingleWomen: ServicesSupport = {
  title: "Social Support for Single Women",
  banners: [
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1593789608/offering_4_a9b910830c.jpg",
    },
  ],
  photos: [
    {
      small:
        "https://res.cloudinary.com/devkhoded/image/upload/v1593789608/offering_4_a9b910830c.jpg",
    },
  ],
};

const servicesSupport: KeyValuePairs = {
  "educational-empowerment": educationalEmpowerment,
  "social-support-for-single-women": socialSupportForSingleWomen,
};

export function fetchServicesSupport(
  id: string
): Promise<ServicesSupport> {
  return new Promise((resolve, reject) => {
    const page = servicesSupport[id];
    if (page) {
      resolve(page);
    } else {
      reject("No page found");
    }
  });
}

export default {
  servicesSupport,
};
