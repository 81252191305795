<script setup lang="ts">
  import { computed, ref, onMounted } from "vue";
  import Date from "@UTILS/date";
  import { VideoItem } from "@ENUMS/pages";

  const props = defineProps<{
    video: VideoItem;
  }>();

  const videoElement = ref<HTMLElement | null>(null);
  const videoPlayer = ref<HTMLElement | null>(null);
  const videoIframe = ref<HTMLElement | null>(null);
  const dialogVisible = ref(false);

  const links = computed(() => {
    return {
      youtube: `https://www.youtube-nocookie.com/embed/${props.video.videoId}?modestbranding=1&showinfo=0&rel=0&fs=0&color=white&disablekb=1`,
      youtubeEmbed: `https://www.youtube.com/embed/${props.video.videoId}?rel=0&showinfo=0&autoplay=1`,
      vimeo: `https://player.vimeo.com/video/${props.video.videoId}`,
    };
  });

  function initVideoPlayer() {
    if (videoElement.value && videoElement.value !== null) {
      const src = `https://img.youtube.com/vi/${props.video.videoId}/sddefault.jpg`;
      videoElement.value.style.backgroundImage = `url(${src})`;
    }
  }

  function showPlayer() {
    dialogVisible.value = true;

    playVideo();
  }

  function playVideo() {
    setTimeout(() => {
      if (videoPlayer.value && videoPlayer.value !== null) {
        var iframe = document.createElement("iframe");
        iframe.setAttribute("frameborder", "0");
        iframe.setAttribute("allowfullscreen", "");
        iframe.setAttribute("width", "100%");
        iframe.setAttribute("height", "100%");
        iframe.setAttribute(
          "class",
          "absolute top-0 left-0 w-full h-full"
        );
        iframe.setAttribute("autoplay", "autoplay");
        iframe.setAttribute("src", links.value.youtubeEmbed);
        videoPlayer.value.innerHTML = "";
        videoPlayer.value.appendChild(iframe);

        videoIframe.value = iframe;
      }
    });
  }

  function handleClose() {
    if (
      videoIframe.value &&
      videoIframe.value !== null &&
      videoPlayer.value &&
      videoPlayer.value !== null
    ) {
      videoIframe.value.setAttribute(
        "class",
        "absolute top-0 left-0 w-full h-full"
      );
      videoIframe.value.removeAttribute("autoplay");
      videoIframe.value.removeAttribute("src");
      videoPlayer.value.innerHTML = "";
    }
    setTimeout(() => {
      dialogVisible.value = false;
    });
  }

  onMounted(() => {
    initVideoPlayer();
  });
</script>

<template>
  <figure
    class="tw-rounded-lg hover:tw-bg-white tw-transition-all hover:tw-shadow-2xl tw-ease-in-out tw-cursor-pointer tw-border tw-border-transparent tw-duration-500 hover:tw-border-accent tw-relative tw-w-1/2 md:tw-w-1/3 xl:tw-w-1/5 tw-flex tw-items-center tw-flex-col tw-text-center lg:tw-py-12 lg:tw-px-8 tw-px-4 tw-py-4"
  >
    <!-- thumbnail -->
    <span
      class="tw-rounded-lg tw-shadow-2xl tw-flex tw-items-center tw-bg-white tw-justify-center lg:tw-w-48 lg:tw-h-48 tw-w-32 tw-h-32 tw-mb-8 tw-overflow-hidden"
    >
      <div
        v-if="props.video.source.toLowerCase() === 'youtube'"
        ref="videoElement"
        class="tw-w-full tw-h-full thumbnail"
      ></div>
    </span>

    <figcaption>
      <h4
        class="tw-pb-4 lg:tw-text-base tw-text-sm tw-font-medium lg:tw-font-normal tw-text-solid"
      >
        {{ props.video.title }}
      </h4>
      <p class="tw-text-gray-400 tw-text-xs tw-pb-7">
        {{ Date(props.video.updatedAt).formatDMY() }}
      </p>

      <div class="cta tw-invisible">
        <ElButton round text @click="showPlayer">
          <Material icon="play_arrow" />
        </ElButton>
      </div>
    </figcaption>
  </figure>

  <ElDialog
    v-model="dialogVisible"
    :title="props.video.title"
    :before-close="handleClose"
    :close-on-click-modal="false"
    class="!tw-w-[80vw] lg:!tw-w-[60vw]"
  >
    <div class="video-popup tw-h-[60vh] tw-bg-primary">
      <div ref="videoPlayer" class="tw-h-full tw-w-full"></div>
    </div>
  </ElDialog>
</template>

<style lang="scss" scoped>
  .thumbnail {
    @include bgImageDefault("");
  }
  figure {
    &:hover {
      .cta {
        visibility: visible;
      }
    }
  }
</style>
