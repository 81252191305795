<script setup lang="ts">
  import { computed, ref, watch, onMounted } from "vue";
  import { useQuery } from "@tanstack/vue-query";
  import { fetchInnerPage } from "@ENUMS/pages";

  import Routes from "@/enums/Routes";
  import router from "@/router";

  import PageHeader from "@COMP/base/utilities/PageHeader.vue";
  import PageError from "@COMP/base/skeletons/PageError.vue";
  import InnerPageSkeleton from "@COMP/base/skeletons/InnerPageSkeleton.vue";

  const pageId = computed(() => "family-life-education");

  const activeTab = ref("");
  const tabs = ref([
    {
      title: "Couples Enrichment",
      link: `${Routes.FAMILY_LIFE_EDUCATION.path}/couple-enrichment`,
    },
    {
      title: "Singles Enrichment",
      link: `${Routes.FAMILY_LIFE_EDUCATION.path}/singles-enrichment`,
    },
    {
      title: "Parents Enrichment",
      link: `${Routes.FAMILY_LIFE_EDUCATION.path}/parents-enrichment`,
    },
    {
      title: "Teens Enrichment",
      link: `${Routes.FAMILY_LIFE_EDUCATION.path}/teens-enrichment`,
    },
    {
      title: "Workplace Enrichment",
      link: `${Routes.FAMILY_LIFE_EDUCATION.path}/workplace-enrichment`,
    },
    {
      title: "Churches Enrichment",
      link: `${Routes.FAMILY_LIFE_EDUCATION.path}/churches-enrichment`,
    },
  ]);

  const { isPending, isError, data, refetch } = useQuery({
    queryKey: ["innerPage", pageId],
    queryFn: () => fetchInnerPage(pageId.value),
    staleTime: Infinity,
  });

  watch(activeTab, (val) => {
    router.push(val);
  });

  onMounted(() => {
    activeTab.value = tabs.value[0].link;
  });
</script>

<template>
  <div class="inner-page">
    <div class="inner-page__inner">
      <InnerPageSkeleton v-if="isPending" />
      <PageError v-else-if="isError" :retry="refetch" />
      <template v-else-if="data">
        <div class="tw-container tw-mx-auto">
          <PageHeader :title="data.title" :center="false" />
        </div>
        <div
          v-if="data.banner"
          class="banner tw-h-[50vh] tw-bg-primary slider sm:tw-h-[50vh] md:tw-h-[50vh] lg:tw-h-[50vh] xl:tw-h-[50vh] tw-relative"
          :style="{
            backgroundImage: `url(${data.banner})`,
          }"
        ></div>
        <div class="tw-my-8 tw-container tw-px-4 tw-mx-auto">
          <ElTabs tab-position="top" v-model="activeTab">
            <ElTabPane
              v-for="(tab, i) in tabs"
              :key="i"
              :label="tab.title"
              :name="tab.link"
            >
              <RouterView v-if="activeTab === tab.link"></RouterView>
            </ElTabPane>
          </ElTabs>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .inner-page {
    &__inner {
      padding-top: $k-nav-height;
      .banner {
        @include bgImageDefault("");
      }
    }
  }
</style>
