<script setup lang="ts">
  import { AnOfferingListing } from "@/types/Homepage";

  const offerings: AnOfferingListing[] = [
    {
      name: "Family Life Education",
      shortDescription:
        "Skills, attitudes and values for Churches, Couples, Parents, and Teenagers",
      thumbnailImage: "/imgs/icons/family-life.png",
      link: "",
    },
    {
      name: "Educational Empowerment (Scholarships)",
      shortDescription:
        "The education of referred Indigents persons in Nigeria is supported through solicited scholarships and donations.",
      thumbnailImage: "/imgs/icons/educational-support.png",
      link: "",
    },
    {
      name: "Social Support for Single Women",
      shortDescription:
        "We provide social support for referred indigent single parents in Nigeria.",
      thumbnailImage: "/imgs/icons/women-support.png",
      link: "",
    },
  ];
</script>

<template>
  <section
    class="tw-my-5 tw-flex tw-justify-center tw-items-center offerings xl:tw-my-8"
  >
    <div
      class="tw-py-5 tw-container tw-mx-auto tw-px-4 offerings__inner tw-relative"
    >
      <div class="tw-text-right offerings__inner__title">
        <h5
          class="tw-pb-5 tw-text-lg tw-font-light lg:tw-text-xl xl:tw-text-3xl"
        >
          We're happy you're here.
        </h5>
        <h1
          class="tw-text-4xl tw-italic tw-font-semibold tw-text-solid tw-pb-10 lg:tw-text-6xl xl:tw-text-8xl"
        >
          Our Services
        </h1>
      </div>
      <div
        class="tw-pt-5 list tw-justify-center tw-flex tw-flex-wrap offerings__inner__list before:tw-invisible before:xl:tw-visible tw-relative"
      >
        <figure
          v-for="(offering, i) in offerings"
          :key="i"
          class="tw-rounded-lg hover:tw-bg-white tw-transition-all hover:tw-shadow-2xl tw-duration-500 tw-ease-in-out tw-cursor-pointer tw-w-1/2 tw-relative tw-flex tw-items-center tw-flex-col tw-p-3 tw-text-center md:tw-w-1/3 lg:tw-w-1/4 xl:tw-w-1/5 xl:tw-py-12 xl:tw-px-8"
        >
          <span
            class="tw-mb-3 xl:tw-mb-8 tw-rounded-full tw-shadow-2xl tw-transition-all tw-duration-500 tw-ease-in-out tw-w-24 tw-h-24 tw-flex tw-items-center tw-justify-center tw-bg-white xl:tw-w-48 xl:tw-h-48"
          >
            <img
              :src="offering.thumbnailImage"
              class="tw-w-16 xl:tw-w-32"
            />
          </span>

          <figcaption>
            <h4
              class="tw-pb-4 tw-text-base tw-font-normal tw-text-solid xl:tw-text-2xl"
            >
              {{ offering.name }}
            </h4>
            <p
              class="tw-text-gray-600 tw-pb-3 tw-text-sm xl:tw-pb-7 xl:tw-text-base"
            >
              {{ offering.shortDescription }}
            </p>

            <div class="cta tw-invisible">
              <ElButton round text>
                <Material icon="arrow_right_alt" />
              </ElButton>
            </div>
          </figcaption>
        </figure>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  .offerings {
    &__inner {
      &__list {
        &::before {
          content: "";
          position: absolute;
          top: 9rem;
          right: 0;
          left: 0;
          height: 1px;
          background-color: $k-accent-hex;
        }
        figure {
          &:hover {
            span {
              @apply tw-shadow-none;
            }
            .cta {
              visibility: visible;
            }
          }
        }
      }
    }
  }
</style>
