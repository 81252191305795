<script setup lang="ts">
  import { useQuery } from "@tanstack/vue-query";
  import { fetchBlog } from "@ENUMS/pages";

  const { isPending, isError, data } = useQuery({
    queryKey: ["blog"],
    queryFn: () => fetchBlog(),
    staleTime: Infinity,
  });
</script>

<template>
  <section
    class="tw-flex tw-justify-center tw-items-center home-blog tw-relative tw-mt-8"
  >
    <div
      class="home-blog__inner tw-py-5 tw-container tw-mx-auto tw-pb-16 tw-pt-5 tw-px-4"
    >
      <div class="tw-text-right home-blog__inner__title">
        <h5
          class="tw-pb-5 xk:tw-text-3xl tw-font-light tw-text-lg lg:tw-text-xl"
        >
          Glad you're still here!
        </h5>
        <h1
          class="xl:tw-text-7xl tw-italic tw-font-semibold tw-text-solid tw-pb-10 tw-drop-shadow-lg lg:tw-text-6xl tw-text-4xl"
        >
          Our Blog
        </h1>
      </div>
      <div
        v-if="!isPending && !isError && data"
        class="tw-pt-5 tw-justify-center home-blog__inner__list tw-flex tw-flex-wrap tw-relative before:tw-top-16 before:lg:tw-top-20 before:xl:tw-top-36"
      >
        <BlogItem
          v-for="(blog, i) in data.blogs.slice(0, 5)"
          :key="i"
          :blog="blog"
        />
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  .home-blog {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      @include diagonalPrimaryPattern(0.2);
    }
    &__inner {
      &__list {
        &::before {
          content: "";
          position: absolute;
          right: 0;
          left: 0;
          height: 1px;
          background-color: $k-accent-hex;
        }
      }
    }
  }
</style>
