<script setup lang="ts"></script>

<template>
  <div class="tw-container tw-px-4 tw-py-8 tw-mx-auto">
    <div class="lg:tw-max-w-4xl tw-mx-auto">
      <h5 class="tw-font-bold tw-text-3xl">...</h5>
    </div>
  </div>
  <div
    class="tw-h-[50vh] tw-bg-primary slider sm:tw-h-[50vh] md:tw-h-[50vh] lg:tw-h-[50vh] xl:tw-h-[50vh] tw-relative"
  ></div>
</template>
